/* eslint-disable */
import React, { useEffect, useMemo } from 'react';
import {
  makeStyles,
  useTheme,
  useMediaQuery,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import LitepaperContent from './LitepaperContent';
import { contents } from './content';
import useTitle from 'hooks/useTitle';

function Litepaper() {
  const classes = useStyles();
  const history = useHistory<{ signal: string }>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onRedirectHome = () => {
    history.push('/');
  };

  const onRedirectLitepaper = () => {
    history.push('/litepaper');
  };
  const state = history.location.state;

  useEffect(() => {
    const timerID = setTimeout(() => {
      if (state?.signal) {
        const contentNine = document.getElementById('9. Privacy Policy');
        if (contentNine) {
          contentNine.scrollIntoView({
            block: isMobile ? 'end' : 'start',
            behavior: 'smooth',
          });
        }
      }
    }, 0);
    return () => clearTimeout(timerID);
  }, [state, isMobile]);

  useTitle('Litepaper | 1001 Squares of NFT');

  return (
    <div>
      <div className={classes.crumpet}>
        <span onClick={onRedirectHome}>Home · </span>
        <span
          onClick={onRedirectLitepaper}
          className={classes.crumpetTermserive}
        >
          Litepaper
        </span>
      </div>
      <div className={classes.container}>
        {/* <Typography className={classes.title}>Litepaper</Typography> */}

        <div>
          {contents.map((item) => (
            <LitepaperContent
              key={item.title}
              content={item.content}
              title={item.title}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Litepaper;

const useStyles = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      padding: '200px 293px 153px 293px',
      backgroundColor: isDarkMode
        ? theme.colors.backgroundDark
        : theme.colors.backgroundLight,
      [theme.breakpoints.down('md')]: {
        padding: '136px 24px 140px 24px',
      },
    },
    crumpet: {
      color: '#4fffa6',
      cursor: 'pointer',
      fontWeight: 500,
      position: 'relative',
      marginLeft: 103,
      [theme.breakpoints.down('md')]: {
        top: '150px',
        marginLeft: 24,
        marginBottom: 20,
      },
      top: '180px',
    },
    crumpetTermserive: {
      fontWeight: 700,
    },
    six: {
      fontSize: 24,
      fontWeight: 300,
      [theme.breakpoints.down('sm')]: {
        fontSize: 14,
      },
    },
    title: {
      fontSize: 42,
      fontWeight: 700,
      lineHeight: '58.8px',
      marginBottom: 12,
    },
  };
});
