import { Avatar, Box, Button, makeStyles, Typography } from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';
import CommonDialog from 'components/common/CommonDialog';
import { SecureStorageEnum } from 'enums/auth';
import CloseIcon from 'icons/CloseIcon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateAppStateAction } from 'store/actions/appActions';
import { updateDialogStateAction } from 'store/actions/dialogActions';
import {
  getDialogState,
  getEditNFT,
  getMarket,
  getNFTEstimate,
  getUserState,
} from 'store/selectors';
import secureStorageUtils from 'utils/secureStorage';
import {
  coinbaseWallet,
  injected,
  walletconnect,
} from '../../services/wallet/connector';
import ConnectMethod from './ConnectMethod';
import { isMobileFn } from 'common/helper';
import { GoogleViaTipLinkWalletName } from '@tiplink/wallet-adapter';
import { useWallet } from '@solana/wallet-adapter-react';
import { BaseWalletConnectButton } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletName } from '@solana/wallet-adapter-phantom';

import { WalletEnum } from 'enums/wallet';
import { updateMarketStateAction } from 'store/actions/marketAction';
import { TRANSPARENCY } from 'material/colors';

declare global {
  interface Window {
    web3: any;
    ethereum: any;
  }
}

const ConnectWalletDialog = () => {
  const classes = useStyles();

  const { account, library, chainId, error, active, activate, deactivate } =
    useWeb3React();
  const { select, publicKey, connected, disconnect } = useWallet();
  const [blockNumber, setBlockNumber] = useState<number | undefined | null>();
  const dispatch = useDispatch();
  const user = useSelector(getUserState);
  const dialog = useSelector(getDialogState);
  const nftAddEstimate = useSelector(getNFTEstimate);
  const nftEditEstimate = useSelector(getEditNFT);
  const market = useSelector(getMarket);

  const accountWallet = useMemo(() => {
    if (
      (market === WalletEnum.PHANTOM || market === WalletEnum.TIPLINK) &&
      publicKey
    ) {
      return publicKey.toBase58();
    }
    if (
      market === WalletEnum.META_MASK ||
      market === WalletEnum.COIN_BASE ||
      market === WalletEnum.WALLET_CONNECT
    ) {
      return account;
    }
    return undefined;
  }, [account, market, publicKey]);

  const activeWallet = useMemo(() => {
    if (market === WalletEnum.PHANTOM || market === WalletEnum.TIPLINK) {
      return connected;
    }
    if (
      market === WalletEnum.META_MASK ||
      market === WalletEnum.COIN_BASE ||
      market === WalletEnum.WALLET_CONNECT
    ) {
      return active;
    }
    return false;
  }, [active, connected, market]);

  const deactivateWallet = useCallback(() => {
    if (market === WalletEnum.PHANTOM || market === WalletEnum.TIPLINK) {
      disconnect();
    }
    if (
      market === WalletEnum.META_MASK ||
      market === WalletEnum.COIN_BASE ||
      market === WalletEnum.WALLET_CONNECT
    ) {
      deactivate();
    }
  }, [deactivate, disconnect, market]);

  useEffect(() => {
    if (error) {
      const isMobile = isMobileFn();
      const snackContent = !isMobile
        ? 'An unknown error occurred. Check the console for more details'
        : 'No Ethereum browser extension  deected, install MetaMask on desktop or visit from a dApp browser on Mobile.';
      dispatch(
        updateAppStateAction({
          isShowSnack: true,
          snackContent,
          type: 'error',
          snackLabel: 'Connection error',
        }),
      );
      dispatch(updateMarketStateAction(null));
    }
  }, [error, dispatch]);

  useEffect(() => {
    if (library) {
      let stale = false;
      library
        .getBlockNumber()
        .then((blockNumber: number) => {
          if (!stale) {
            setBlockNumber(blockNumber);
          }
        })
        .catch(() => {
          if (!stale) {
            setBlockNumber(null);
          }
        });

      const updateBlockNumber = (blockNumber: number) => {
        setBlockNumber(blockNumber);
      };
      library.on('block', updateBlockNumber);

      return () => {
        library.removeListener('block', updateBlockNumber);
        stale = true;
        setBlockNumber(undefined);
      };
    }
  }, [library, chainId]);

  const onLogin = async (name: WalletEnum) => {
    if (name === market) return;
    dispatch(updateMarketStateAction(name));
    try {
      switch (name) {
        case WalletEnum.META_MASK:
          activateInjectedProvider('MetaMask');
          activate(injected);
          break;
        case WalletEnum.WALLET_CONNECT:
          activateInjectedProvider('Coinbase');
          activate(walletconnect);
          break;
        case WalletEnum.COIN_BASE:
          activate(coinbaseWallet);
          break;
        case WalletEnum.PHANTOM:
          await loginByPhantom();
          break;
        case WalletEnum.TIPLINK:
          await loginTipLink();
          break;
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onLogout = () => {
    deactivateWallet();
    dispatch(updateMarketStateAction(null));
    secureStorageUtils.removeItem(SecureStorageEnum.ACCOUNT);
  };

  const onClose = () => {
    dispatch(
      updateDialogStateAction({
        open: false,
      }),
    );
    dispatch(
      updateAppStateAction({
        isShowSnack: false,
      }),
    );
  };

  const isActive = (name: string) => {
    return market === name && !!user.user && activeWallet;
  };

  useEffect(() => {
    if (!user.user || !activeWallet) return;
    const { market: nMarket } = JSON.parse(
      secureStorageUtils.getItem(SecureStorageEnum.ACCOUNT) || '{}',
    );
    const newMarket = market || nMarket;
    secureStorageUtils.setItem(
      SecureStorageEnum.ACCOUNT,
      JSON.stringify({
        market: newMarket,
        blockNumber,
        account: accountWallet,
        chainId,
        isAdmin: user.user.isAdmin,
      }),
    );
    dispatch(
      updateDialogStateAction({
        open: false,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, activeWallet]);

  const renderBottom = useMemo(() => {
    if (!activeWallet || !user.user)
      return (
        <>
          <Typography>Don’t see your wallet provider?</Typography>
          <Typography>If you're on desktop, try Metamask</Typography>
          <Typography>If you're on mobile, try WalletConnect</Typography>
        </>
      );
  }, [activeWallet, user]);

  useEffect(() => {
    if (!dialog.props) return;
  }, [dialog, nftAddEstimate, nftEditEstimate, chainId]);

  const loginByPhantom = async () => {
    if (!connected) {
      select(PhantomWalletName);
    }
  };

  async function loginTipLink() {
    if (!connected) {
      select(GoogleViaTipLinkWalletName);
    }
  }

  return (
    <CommonDialog>
      <Box className={classes.main}>
        <Box>
          <Button onClick={onClose}>
            <CloseIcon />
          </Button>
        </Box>
        <Typography>Connect wallet</Typography>
        <Box className={classes.method}>
          {connectMethods.map((c) => (
            <ConnectMethod
              address={user.user?.address}
              data={c}
              key={c.name}
              active={isActive(c.name)}
              onLogin={onLogin}
              onLogout={onLogout}
              disabled={false}
            />
          ))}
        </Box>
        <Box className={classes.bottom}>{renderBottom}</Box>
      </Box>
    </CommonDialog>
  );
};

export default ConnectWalletDialog;

export const activateInjectedProvider = (
  providerName: 'MetaMask' | 'Coinbase',
) => {
  const { ethereum } = window;
  if (!ethereum?.providers) {
    return undefined;
  }

  let provider;
  switch (providerName) {
    case 'Coinbase':
      provider = ethereum.providers.find(
        ({ isCoinbaseWallet }: { isCoinbaseWallet: boolean }) =>
          isCoinbaseWallet,
      );
      break;
    case 'MetaMask':
      provider = ethereum.providers.find(
        ({ isMetaMask }: { isMetaMask: boolean }) => isMetaMask,
      );
      break;
  }
  if (provider) {
    ethereum.setProvider(provider);
  }
};

const connectMethods = [
  {
    logoUrl: '/images/phantom-wallet.png',
    name: WalletEnum.PHANTOM,
  },
  {
    logoUrl: '/images/metamask-fox.png',
    name: WalletEnum.META_MASK,
  },
  {
    logoUrl: '/images/wallet-connect.png',
    name: WalletEnum.WALLET_CONNECT,
  },
  {
    logoUrl: '/images/coinbase-wallet.png',
    name: WalletEnum.COIN_BASE,
  },
  {
    logoUrl: '/images/tiplink-wallet.svg',
    name: WalletEnum.TIPLINK,
  },
];

const useStyles = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    main: {
      padding: 29,
      width: 337,
      overflow: 'hidden',
      backgroundColor: isDarkMode
        ? theme.colors.backgroundDark
        : theme.colors.backgroundLight,
      '&>div:nth-child(1)': {
        display: 'flex',
        justifyContent: 'flex-end',
        '& button': {
          padding: 0,
          minWidth: 24,
        },
      },
      '&>p:nth-child(2)': {
        fontSize: 16,
        fontWeight: 700,
        marginTop: 29,
      },
      '&>p:nth-child(3)': {
        marginTop: 24,
        color: 'rgba(16, 1, 19, 0.6)',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    method: {
      margin: '24px 0px',
      '&>div:first-child img': {
        width: 32,
        height: 32,
      },
      '&>div:nth-child(2) img': {
        width: 29,
        height: 18,
      },
      '&>div:nth-child(3) img': {
        width: 24,
        height: 24,
      },
      '&>div:nth-child(4) img': {
        width: 24,
        height: 24,
      },
    },
    bottom: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      '& p': {
        color: isDarkMode ? theme.colors.white : theme.colors.black,
        fontSize: 13,
      },
      '& p:nth-child(1)': {
        fontWeight: 700,
        marginBottom: 8,
      },
      '& button span p': {
        color: '#4fffa6 !important',
        fontWeight: '600 !important',
        textTransform: 'none',
        fontSize: '14px !important',
      },
    },
    payBtn: {
      backgroundColor: '#4fffa6',
      height: 40,
      width: '100%',
      color: '#FFFFFF',
      fontWeight: 600,
      lineHeight: 12,
      textTransform: 'none',
    },
    img: {
      width: 40,
      height: 40,
      // border: '2px solid rgba(111, 107, 197, 0.16)',
      '& img': {
        objectFit: 'contain',
      },
    },
  };
});
