import React, { useState } from 'react';
import {
  OutlinedInput,
  Box,
  makeStyles,
  useMediaQuery,
  useTheme,
  ClickAwayListener,
} from '@material-ui/core';
import SearchIcon from 'icons/SearchIcon';
import { debounce } from 'lodash';
import { questionContent } from './questionContent';
import { IQuestionContent } from './questionContent';
import SearchResult from './SearchResult';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { TRANSPARENCY } from 'material/colors';

interface ISearch {
  className?: string;
  classContainer?: string;
}

interface ISearchStyle {
  inputActive: boolean;
  isquestionDetailPage: boolean;
}

function Search({ className, classContainer }: ISearch) {
  const history = useHistory();
  const location = useLocation();

  const isquestionDetailPage = location.pathname !== '/faq/all';

  const [inputActive, setInputActive] = useState<boolean>(false);
  const classes = useStyle({ inputActive, isquestionDetailPage });
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [showResult, setShowResult] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<IQuestionContent[]>([]);

  const onRedirectToAllQuestion = () => {
    if (location.pathname === '/faq/all') return;
    history.push('/faq/all');
  };

  const onRedirectHome = () => {
    history.push('/');
  };

  const handleChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLowerCase();
    if (searchValue === '') {
      setShowResult(false);
      return;
    }
    const questionResultIndex = questionContent
      .map((q, i) => ({ question: q.question.toLowerCase(), index: i }))
      .filter((q) => q.question.includes(searchValue))
      .map((q) => q.index);
    const questionResult = questionResultIndex.map((q) => questionContent[q]);
    setSearchResult(questionResult);
    setShowResult(true);
  }, 500);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setInputActive(true);
    if (e.target.value !== '') setShowResult(true);
  };

  const handleBlur = () => {
    setInputActive(false);
  };
  return (
    <Box className={clsx(classes.container, classContainer)}>
      {isDesktop && (
        <p className={classes.title}>
          <span onClick={onRedirectHome}>Home · </span>
          <span onClick={onRedirectToAllQuestion} style={{ fontWeight: 700 }}>
            FAQ
          </span>
        </p>
      )}
      <ClickAwayListener onClickAway={() => setShowResult(false)}>
        <div className={clsx(classes.searchInput, className)}>
          <OutlinedInput
            className={classes.outlineInput}
            startAdornment={<SearchIcon width={17.5} height={17.5} />}
            placeholder="Search"
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          ></OutlinedInput>
          {showResult && (
            <SearchResult
              questionsMatched={searchResult}
              className={classes.questionResult}
              onClick={() => {
                setShowResult(false);
              }}
            />
          )}
        </div>
      </ClickAwayListener>
    </Box>
  );
}

export default Search;

const useStyle = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    container: {
      backgroundColor: ({
        inputActive,
        isquestionDetailPage,
      }: ISearchStyle) => {
        if (inputActive) return 'unset';
        if (isquestionDetailPage) return 'unset';
        return isDarkMode
          ? `${theme.colors.primaryDark}${TRANSPARENCY.T10}`
          : `${theme.colors.secondaryLight}${TRANSPARENCY.T10}`;
      },
      display: 'grid',
      position: 'relative',
      height: '144px !important',
      boxSizing: 'unset',
      minHeight: 'unset !important',
      [theme.breakpoints.up('lg')]: {
        height: '248px !important',
      },
      [theme.breakpoints.down('md')]: {
        padding: '0 27px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '0 16px',
      },
    },
    title: {
      position: 'absolute',
      color: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.secondaryLight,
      fontWeight: 500,
      cursor: 'pointer',
      marginTop: 20,
      bottom: ({ isquestionDetailPage }: ISearchStyle) =>
        isquestionDetailPage ? 75 : 'unset',
      left: ({ isquestionDetailPage }: ISearchStyle) =>
        isquestionDetailPage ? 0 : 103,
    },

    searchInput: {
      position: 'relative',
      width: '100%',
      borderRadius: 0,
      height: 60,
      margin: 'auto',
      backgroundColor: isDarkMode
        ? theme.colors.blackOlive
        : theme.colors.cultured,
      '& input': {
        marginLeft: 20,
        outline: 'none',
      },
      [theme.breakpoints.up('lg')]: {
        width: 506,
      },
    },
    outlineInput: {
      width: '100%',
      height: '100%',
    },
    questionResult: {
      overflow: 'auto',
      maxHeight: '250px',
      width: '94%',
      padding: 12,
      position: 'absolute',
      zIndex: 2,
      top: 66,
      left: '50%',
      transform: 'translate(-50%, 0)',
      backgroundColor: isDarkMode
        ? theme.colors.blackOlive
        : theme.colors.cultured,
      boxShadow:
        '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
    },
  };
});
