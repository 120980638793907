import React, { memo } from 'react';
import { IIconProperty } from 'common/type';
import { SvgIcon } from '@material-ui/core';

function EthMenuItem({ width, height, color }: IIconProperty) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 12 12"
      style={{ transform: 'scale(1)', width, height }}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g clip-path="url(#clip0_2246_996)">
        <g clip-path="url(#clip1_2246_996)">
          <path
            d="M5.99859 0L5.91797 0.273381V8.20627L5.99859 8.28656L9.68981 6.10993L5.99859 0Z"
            fill="#3F6F93"
          />
          <path
            d="M5.99981 0L2.30859 6.10993L5.99981 8.28659V4.4362V0Z"
            fill="#65AEBF"
          />
          <path
            d="M5.99856 8.98365L5.95312 9.0389V11.8648L5.99856 11.9972L9.69196 6.80811L5.99856 8.98365Z"
            fill="#3F6F93"
          />
          <path
            d="M5.99981 11.9972V8.98365L2.30859 6.80811L5.99981 11.9972Z"
            fill="#65AEBF"
          />
          <path
            d="M6 8.28637L9.69115 6.10976L6 4.43604V8.28637Z"
            fill="#1C0222"
          />
          <path
            d="M2.30859 6.10976L5.99975 8.28637V4.43604L2.30859 6.10976Z"
            fill="#3F6F93"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2246_996">
          <rect width="12" height="12" fill="white" />
        </clipPath>
        <clipPath id="clip1_2246_996">
          <rect
            width="7.38462"
            height="12"
            fill="white"
            transform="translate(2.30859)"
          />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default memo(EthMenuItem);
