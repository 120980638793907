/* eslint-disable */
import React from 'react';
import { Box, Divider, makeStyles, Typography } from '@material-ui/core';
import ArrowNext from 'icons/ArrowNext';
import { useHistory, useLocation } from 'react-router-dom';
import { IQuestionContent } from './questionContent';
import DownArrowIcon from 'icons/DownArrowIcon';
import clsx from 'clsx';
import { isNil } from 'lodash';
import { TRANSPARENCY } from 'material/colors';

interface IQuestion {
  questionValue: IQuestionContent;
  isDetail: boolean;
  downArrow?: boolean;
  onClick?: () => void;
  className?: string;
}

const Question = (props: IQuestion) => {
  const { questionValue, isDetail, downArrow, onClick, className } = props;
  const history = useHistory<number | undefined>();
  const location = useLocation();

  const idQuestion = history.location.state;

  const isActive = !isNil(idQuestion) ? idQuestion === questionValue.id : false;
  const classes = useStyles({ isActive });

  const onRedirectToQuestionDetail = () => {
    if (onClick) onClick();
    if (location.pathname === `/faq/${questionValue.question}`) return;
    {
      history.push({
        pathname: `/faq/${questionValue.question}`
          .replace(/ /g, '-')
          .replace('?', '-'),
        state: questionValue.id,
      });
    }
  };

  return (
    <Box>
      <Box
        className={clsx(classes.boxQuestion, className)}
        onClick={downArrow ? onClick : onRedirectToQuestionDetail}
      >
        <Typography className={classes.question}>
          {questionValue.question}
        </Typography>
        {!isDetail && (
          <Box className={classes.icon}>
            <ArrowNext />
          </Box>
        )}
        {downArrow && (
          <Box className={classes.icon}>
            <DownArrowIcon width={12} height={8} />
          </Box>
        )}
      </Box>
      <Divider className={classes.divider} />
    </Box>
  );
};

export default Question;

const useStyles = makeStyles((theme) => {
  const isDarkMode = theme.palette.type === 'dark';
  return {
    boxQuestion: {
      display: 'flex',
      flexDirection: 'row',
      background: ({ isActive }: { isActive: boolean }) => {
        if (isActive) {
          return isDarkMode
            ? `${theme.colors.secondaryDark}${TRANSPARENCY.T10}`
            : `${theme.colors.secondaryLight}${TRANSPARENCY.T10}`;
        }
        return '';
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },
    question: {
      fontSize: 16,
      fontWeight: 700,
      lineHeight: '22.4px',
      padding: '20px 24px 20px 24px',
      width: '100%',
    },
    icon: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginRight: 25,
      color: isDarkMode
        ? theme.colors.secondaryDark
        : theme.colors.primaryLight,
    },
    divider: {
      backgroundColor: isDarkMode
        ? `${theme.colors.secondaryDark}${TRANSPARENCY.T10}`
        : `${theme.colors.secondaryLight}${TRANSPARENCY.T10}`,
    },
  };
});
