import { PaletteMode } from '@mui/material';
import colors from './colors';

export const getPalette = (mode: PaletteMode) => ({
  mode,
  type: mode,
  ...(mode === 'light'
    ? {
        primary: {
          main: colors.black2,
        },
        secondary: {
          main: colors.purple1,
        },
        text: {
          primary: colors.black,
        },
        background: {
          default: colors.white,
          paper: colors.black2,
        },
        divider: colors.black,
      }
    : {
        primary: {
          main: colors.black2,
        },
        secondary: {
          main: colors.purple1,
        },
        text: {
          primary: colors.white,
        },
        background: {
          default: colors.white,
          paper: colors.black2,
        },
        divider: colors.black,
      }),
});
