import React, { memo } from 'react';
import { IIconProperty } from 'common/type';
import { SvgIcon } from '@material-ui/core';

function MaticMenuItem({ width, height, color }: IIconProperty) {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 12 12"
      style={{ transform: 'scale(1)', width, height }}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <g clip-path="url(#clip0_2246_23619)">
        <path
          d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
          fill="#0B0E11"
        />
        <path
          d="M4.31824 5.30829L6.00133 3.62524L7.68527 5.30918L8.66459 4.3298L6.00133 1.6665L3.33889 4.32894L4.31824 5.30829ZM2.64734 5.02042L3.62668 5.99975L2.64729 6.97914L1.66797 5.9998L2.64734 5.02042ZM4.31824 6.69137L6.00133 8.37437L7.68523 6.69052L8.66512 7.66937L8.66459 7.66987L6.00133 10.3332L3.33886 7.67074L3.33749 7.66939L4.31824 6.69137ZM10.3347 5.99993L9.35539 6.97925L8.37607 5.99993L9.35539 5.02059L10.3347 5.99993Z"
          fill="#F0B90B"
        />
        <path
          d="M6.99153 5.99966H6.99198L5.99819 5.00586L5.00391 6.00014L5.00526 6.00153L5.99819 6.99445L6.99244 6.00014L6.99153 5.99966Z"
          fill="#F0B90B"
        />
      </g>
      <defs>
        <clipPath id="clip0_2246_23619">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export default memo(MaticMenuItem);
