export const contents = [
  {
    title: 'INTRODUCTION ',
    content:
      '<p>Imagine minting an NFT worth over a million dollars. How do you sell it for a fair price when thousands of potential buyers don&rsquo;t know the collection, can&rsquo;t see what the NFT looks like, and selling at the floor price is an unfair option? Traditional art galleries and auction houses might take over 20% of your returns, leaving you with even less</p><p>Our founder faced this exact challenge. He searched for a platform where NFTs could be showcased either for free or ranked based on how much users were willing to pay per day. A place where the entire NFT community could gather, regardless of chain, collection, category, or marketplace.</p><p>The purpose of&nbsp;<strong>1001 Squares of NFT</strong>&nbsp;is to connect the NFT community. We offer sellers a place to showcase their NFTs to a broad audience with ease and no commissions, while buyers can explore a wide variety of opportunities in a democratized space.</p>',
  },
  {
    title: 'WHY 1001 SQUARES OF NFT',
    content:
      '<p>Our main purpose is to become the &ldquo;front page of NFT.&rdquo; We aim to be the main platform where the NFT community links and showcases their NFTs, where sellers and collections compete for the community&rsquo;s attention.</p> <p>We make it easier to sell NFTs by providing sellers with access to a larger audience and allowing buyers to discover hundreds of NFTs shared by the community. With 42 categorized galleries, such as /SOL, /GIF, and /FREE, NFTs can be linked and displayed in multiple galleries, helping the right buyers find what they&rsquo;re looking for.</p> <p>Our goal is to be a home for all NFTs, regardless of chain or marketplace. If you can link it, you can add it to 1001 Squares of NFT.</p>',
  },
  {
    title: 'ECOSYSTEM',
    content:
      '<p><strong>1001 Squares of NFT</strong>&nbsp;features 42 galleries, each containing 1001 squares where NFTs can be displayed based on their attributes. The main gallery, /NFT, includes all added NFTs, while specific galleries like /ETH or /AZUKI are exclusive to particular chains or collections.</p> <p>NFTs are added by interacting with an audited&nbsp;smart contract&nbsp;through our website. Users can choose how much they want to pay and how long they want their NFT to be linked and displayed. This duration can be edited later.</p><p>NFTs are ranked in each gallery based on their ad price per day&mdash;calculated by dividing the selected amount paid by the duration selected. NFTs can be linked for a short time or even for a million years. The minimum amount to be paid is $0.00 per day, but NFTs added for free can only be displayed for a maximum of 90 days.</p><p>An NFT&rsquo;s position in the gallery may change as new NFTs are added and existing ones run out of time. If a gallery exceeds 1001 NFTs, the lowest-ranked NFTs will be located in the &quot;DeadZone,&quot; the right-most block containing all NFTs after 1001. DeadZone NFTs are not filterable, hence the name, and there is no limit to how many NFTs the DeadZone can hold.</p>',
  },
  {
    title: '$DONE COIN',
    content:
      '<p><strong>$DONE</strong>&nbsp;on Solana is the native token of 1001 Squares of NFT. It can be used to add NFTs like our other available payment methods:&nbsp;<strong>Solana</strong>,&nbsp;<strong>Polygon</strong>,&nbsp;<strong>Avalanche</strong>,&nbsp;<strong>BNB Smart Chain</strong>,&nbsp;<strong>Ethereum</strong>, and&nbsp;<strong>Fantom</strong>.</p> <p>The goal of $DONE is to build a community-focused utility coin, rewarding users for interacting with the platform.&nbsp;</p> <p>To encourage early use, we&rsquo;ll be giving users 99% of their total ad spend back in $DONE when adding NFTs with Solana or $DONE. As the platform grows, this rebate will gradually decrease to 69%, 42%, 24%, and finally 11%.</p>',
  },
  {
    title: '$DONE TOKENOMICS',
    content: `
        <p>The total supply of&nbsp;<strong>$DONE</strong>&nbsp;is capped at 1,000,001 tokens.</p>
          <ul>
              <li>
                  <p><strong>11% for Airdrop</strong></p>
              </li>
              <li>
                  <p><strong>74% for $DONE Rewards Reserve</strong></p>
              </li>
              <li>
                  <p><strong>15% for Liquidity Provision</strong></p>
              </li>
          </ul>
        <p><strong>Airdrop</strong>&nbsp;tokens will be unlocked at the end of the 3-month eligibility period three months after launch (33.3% on Month 4, an additional 33.3% on Month 7, and a final 33.3% on Month 10). The allocation for the&nbsp;<strong>$DONE Rewards Reserve</strong>&nbsp;and<strong>&nbsp;Liquidity Provision</strong>&nbsp;will be released as follows: 20% of Liquidity Provision and Rewards Reserve months 1-6 and the remaining 80% months 7-24.</p>
        <p>This vesting schedule was designed to ensure a balanced and gradual distribution to promote early participation and long-term market stability. No tokens have been allocated to the team to align long-term incentives with those of hodlers, however upon launch the team will use personal funds to buy tokens.</p>
      `,
  },
  {
    title: '$DONE AIRDROP',
    content: `
      <p>The&nbsp;<strong>Ready Player $DONE</strong>&nbsp;airdrop program will take place from the platform&rsquo;s launch on October 1, 2024, until January 1, 2025.</p>
      <p>Every day at 4:20 PM UTC, a snapshot will be taken of the top 1001 NFTs in the main /NFT gallery. Each NFT posting in the top 1001 will be assigned 1 airdrop key.</p>
      <p>The more airdrop keys assigned to a wallet, the larger the airdrop, which will be released on January 1, 2025.&nbsp;</p>
    `,
  },
  {
    title: 'ROADMAP',
    content: `
      <ul>
        <li>
            <p><strong>Q4 2024</strong>: Platform Launch and Airdrop Program Begins</p>
            <ul>
                <li>
                    <p>Launch of the 1001 Squares of NFT platform with 42 galleries</p>
                </li>
                <li>
                    <p>Initiation of the $DONE token airdrop and rewards program at 99%</p>
                </li>
                <li>
                    <p>Marketplace API integration: OpenSea, LooksRare, Magic Eden, etc.</p>
                </li>
                <li>
                    <p>Daily snapshot of the 1001 at 4:20 PM UTC on X and Instagram</p>
                </li>
                <li>
                    <p>Host the GM1001 space on X</p>
                </li>
                <li>
                    <p>Release&nbsp;<em>The Community</em>&mdash;an ongoing web series about the project</p>
                </li>
            </ul>
        </li>
        <li>
            <p><strong>Q1 2025</strong>: Expansion and Feature Enhancements</p>
            <ul>
                <li>
                    <p>Deliver the first two phases of the $DONE Airdrop rewards</p>
                </li>
                <li>
                    <p>Decrease in $DONE rebate percentage to 69%</p>
                </li>
                <li>
                    <p>Introduction of new galleries and removal of poor performing ones</p>
                </li>
                <li>
                    <p>Enhancement of user interface and platform performance</p>
                </li>
                <li>
                    <p>Introduction of unintrusive curated sponsored posts</p>
                </li>
                <li>
                    <p>Introduction of new payment methods to add NFTs</p>
                </li>
            </ul>
        </li>
        <li>
            <p><strong>Q2 2025 and Beyond</strong>: Community Growth and Ecosystem Development</p>
            <ul>
                <li>
                    <p>Introduction of governance features using $DONE</p>
                </li>
                <li>
                    <p>Evaluation of decrease/increase in $DONE rebate percentage</p>
                </li>
                <li>
                    <p>Expansion of the platform to better integrate more marketplaces</p>
                </li>
                <li>
                    <p>Ongoing community events, partnerships, and platform updates</p>
                </li>
                <li>
                    <p>Perks for $DONE hodlers and adding NFTs with $DONE</p>
                </li>
                <li>
                    <p>And much more&hellip;</p>
                </li>
            </ul>
        </li>
    </ul>
    `,
  },
  {
    title: 'WHO WE ARE',
    content:
      '<p>1001 Squares of NFT is brought to you from people all over the world, from Ukraine, to Portugal, to Vietnam, to the United States, and Australia. The vision at the centre of it all comes from our Portuguese/American founder and CEO—Martim Molina. Inspired by the Million Dollar Page created in 2005 by Alex Tew, Martim has dedicated himself to bring forward an iconic place for the NFT community to link NFTs for many years to come. With this long-term vision always in mind, the security of our users and their assets has remained our #1 concern. For this reason we’ve worked with the industry’s top talent in designing low-risk systems, and had our smart contracts audited by the strongest audit companies in the world. Our list of partners includes Hashlock, Twendee Software, 4ireLabs, SourceHat and Omniscia. We look forward to expanding our core team, and to continue working closely with these and other talented companies in the future. </p>',
  },
  {
    title: 'CONCLUSION',
    content: `
      <p><strong>1001 Squares of NFT</strong>&nbsp;is your gateway to showcasing and discovering NFTs in a democratized, commission-free environment. Whether you&rsquo;re a seller looking for visibility or a buyer seeking hidden gems, our platform is designed to link the entire NFT community. With $DONE as our native token, we&rsquo;re committed to rewarding users and building a thriving ecosystem for all NFTs.</p>
      <p>Join us in creating the front page of NFT.</p>
    `,
  },
];
